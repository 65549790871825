import React from "react";

const LoadingSpinner = () => {
  return (
    <>
      <span className="me-2">Sending</span>
      <div className="custom-spinner"></div>
    </>
  );
};

export default LoadingSpinner;
