import React from "react";
import { Link } from "react-router-dom";

//Images
import Icon9 from "./../../assets/images/icons/icon9.svg";
import Icon10 from "./../../assets/images/icons/icon10.svg";
import Icon11 from "./../../assets/images/icons/icon11.svg";
import Icon12 from "./../../assets/images/icons/icon12.svg";
import Icon13 from "./../../assets/images/icons/icon13.svg";
import pic1 from "./../../assets/images/about/pic1.jpg";
import support1 from "./../../assets/images/icons/support1.png";

const cardData = [
  {
    image: Icon9,
    title: "Competitive Pricing",
    text: "Whilst offering some of the best ROI’s to date. Our company takes one of the lowest commissions at only 10% of total net profit.",
  },
  {
    image: Icon10,
    title: "Support",
    text: "We take pride in being one of the only crypto investment services which provide 1 on 1 communication channels between our brokers and clients.",
  },
  {
    image: Icon11,
    title: "Doxed Team",
    text: "With most crypto investment services being run by ghost figures. Our founder takes pride in his creation and can be directly contacted at any time.",
  },
  {
    image: Icon12,
    title: "Security",
    text: "Our clients security and safety is our number one priority. We have gone over and above in order to provide a safe trading environment for our community, Enjoy.",
  },
  {
    image: Icon13,
    title: "Fast Transaction",
    text: "We hold the badge for the fastest crypto investment service, with the most rewarding returns. With our biggest trades taking no longer than 24hours we are the industry leaders in fast transactions.",
  },
];

function OneStop() {
  return (
    <>
      {cardData.map((item, ind) => (
        <div className="col-xl-4 col-md-6 m-b60" key={ind}>
          <div className="icon-bx-wraper style-3 text-center">
            <div className="icon-media">
              <img src={item.image} alt="" />
            </div>
            <div className="icon-content">
              <h4 className="title">{item.title}</h4>
              <p className="m-b0">{item.text}</p>
            </div>
          </div>
        </div>
      ))}
      <div className="col-xl-4 col-md-6 m-b60">
        <div
          className="icon-bx-wraper style-4"
          style={{ backgroundImage: "url(" + pic1 + ")" }}
        >
          <div className="inner-content">
            <div className="icon-media m-b30">
              <img src={support1} alt="" />
            </div>
            <div className="icon-content">
              <Link to={"/contact-us"} className="btn btn-primary">
                Whatsapp Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OneStop;
