import React, { useRef, useState } from "react";

import PageLayout from "./../layouts/PageLayout";
import { backEndUrl, sendFormData } from "../formSubmission";
import { successfulToast } from "../toasts";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import emailjs from '@emailjs/browser';
function ContactUs() {
  const [formData, setFormData] = useState({
    walletAddress: "",
    email: "",
    firstName: "",
    lastName: "",
    amount: "",
    phone: "",
    message: ""
  });
  const form = useRef(null);


  const [emailLoading, setEmailLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    setEmailLoading(true);
    
      if (form.current) {
        emailjs.sendForm('service_xfnsql8', 'template_tc7imh8', form.current, '5AmHByg4twxtIu8ct')
            .then((result) => {
                console.log(result.text);
                successfulToast("Sent! We will reach out to you within 24 hours");
                setFormData({
                  walletAddress: "",
                  email: "",
                  firstName: "",
                  lastName: "",
                  amount: "",
                  phone: "",
                  message: ""
                });
            }, (error) => {
                console.log(error.text);
                alert(error.text);

            });
    } else {
        console.error('Form reference is undefined');
    }
    setEmailLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

  
  };

  return (
    <>
      <div className="page-content">
        <PageLayout desc={false} pageTitle="Contact Us" />
        <section className="content-inner contact-form-wraper style-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-5 m-b30">
                <div className="info-box">
                  <div className="info">
                    <h2>Contact Information</h2>
                    <p className="font-18">
                      Fill up the form and our team will get back to you within
                      24 hours.
                    </p>
                  </div>

                  <div className="widget widget_about">
                    <div className="widget widget_getintuch">
                      <ul>
                        <li>
                          <i className="fa fa-phone"></i>
                          <span>+1 (868) 288‑6618</span>
                        </li>
                        <li>
                          <i className="fa fa-envelope"></i>
                          <span>investments@benefund.io</span>
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt"></i>
                          <span>
                            Aspect Tower, Business Bay <br />
                            Dubai, United Arab Emirates
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="social-box dz-social-icon style-3">
                    <h6>Our Socials</h6>
                    <ul className="social-icon">
                      {/* <li>
                        <a
                          className="social-btn"
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.facebook.com/"
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li> */}
                      <li>
                        <a
                          className="social-btn"
                          target="_blank"
                          rel="noreferrer"
                          href="
                          https://www.instagram.com/stephanshedge?igsh=dnVkY3FpZmhlOW5q&utm_source=qr"
                        >
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="social-btn"
                          target="_blank"
                          rel="noreferrer"
                          href="https://twitter.com/"
                        >
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li> */}
                      {/* <li>
                        <a
                          className="social-btn"
                          target="_blank"
                          rel="noreferrer"
                          href="https://youtube.com/"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-7 col-lg-7">
                <div className="contact-box">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-4">
                        <h2 className="mb-0">Get In touch</h2>
                        <p className="mb-0 font-18 text-primary">
                          We are here for you. How we can help?
                        </p>
                      </div>
                      <form
                        className="dzForm"
                        ref={form} 
                        onSubmit={(e) => submitHandler(e)}
                      >
                        <div className="dzFormMsg"></div>
                        <input
                          type="hidden"
                          className="form-control"
                          name="dzToDo"
                          value="Contact"
                          required
                        />

                        <div className="row">
                          <div className="col-xl-6 mb-3 mb-md-4">
                            <input
                              name="firstName"
                              type="text"
                              className="form-control"
                              required
                              placeholder="First Name"
                              value={formData.firstName}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-xl-6 mb-3 mb-md-4">
                            <input
                              name="lastName"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Last Name"
                              value={formData.lastName}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-xl-6 mb-3 mb-md-4">
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              placeholder="Email Address"
                              required
                              value={formData.email}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-xl-6 mb-3 mb-md-4">
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              required
                              placeholder="What's App No."
                              value={formData.phone}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-xl-6 mb-3 mb-md-4">
                            <input
                              name="walletAddress"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Eth Wallet Address"
                              value={formData.walletAddress}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-xl-6 mb-3 mb-md-4">
                            <input
                              name="amount"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Investment Amount in £"
                              value={formData.amount}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="col-xl-6 mb-3 mb-md-4">
                            <textarea
                              name="message"
                              className="form-control"
                              placeholder="Message..."
                              required
                              value={formData.message}
                              onChange={handleInputChange}
                            />
                          </div>

                          {/* <div className="col-xl-12 mb-3 mb-md-4">
                                                        <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" style="display:none;" data-recaptcha="true"  data-error="Please complete the Captcha" />
                                                    </div> */}
                          <div className="col-xl-12">
                            <button
                              name="submit"
                              type="submit"
                              value="Submit"
                              className="btn btn-primary"
                            >
                              {!emailLoading ? "Submit" : <LoadingSpinner />}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default ContactUs;



