import React from "react";
import Route from "./pages/route";

import "./assets/css/style.css";
import "./assets/css/custom-css.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Route />
    </div>
  );
}

export default App;
