import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalVideo from "react-modal-video";

import PageLayout from "./../layouts/PageLayout";
import PriceBlog from "./../components/About/PriceBlog";

//Images
import Shape1 from "./../assets/images/home-banner/shape1.png";
import Shape3 from "./../assets/images/home-banner/shape3.png";

import founder from "./../assets/images/about/founder.jpg";
import { successfulToast } from "../toasts";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import { backEndUrl, sendFormData } from "../formSubmission";
import emailjs from '@emailjs/browser';

function AboutUs() {
  const [formData, setFormData] = useState({
    walletAddress: "",
    email: "",
    fullName: "",
    amount: "",
    phone: "",
  });
  const [isOpen, setOpen] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const form = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formDetails = async (e) => {
    e.preventDefault();

    setEmailLoading(true);
    if (form.current) {
      emailjs.sendForm('service_xfnsql8', 'template_mh2p7so', form.current, '5AmHByg4twxtIu8ct')
          .then((result) => {
              console.log(result.text);
              successfulToast("Sent! We will reach out to you within 24 hours");
              setFormData({
                walletAddress: "",
                email: "",
                firstName: "",
                lastName: "",
                amount: "",
                phone: "",
              });
          }, (error) => {
              console.log(error.text);
              alert(error.text);

          });
  } else {
      console.error('Form reference is undefined');
  }
    setEmailLoading(false);
  };

  return (
    <>
      <div className="page-content">
        <PageLayout
          pageTitle="About Us"
          pageDesc={
            "Seamlessly connecting investors with Defi’s most experienced traders."
          }
        />
        <section className="content-inner about-sec bg-primary-light">
          <div className="container">
            <div className="row about-bx2 style-1 align-items-center">
              <div className="col-lg-6">
                <div className="section-head">
                  <h2 className="title">Our Company</h2>
                  <p className="m-0 lh-base">
                    We employ more than 100 expert brokers who continuously
                    analyze the cryptocurrency market using technical analysis
                    and fundamental analysis methods. Their meticulous analysis
                    greatly improves our ability to identify suitable short-term
                    and long-term investments on specific coins. Additionally,
                    we have exclusive access to signals from companies that
                    solely cater to large corporate investors and retail
                    millionaires, popularly known as "Whales". This unique
                    combination of resources grants us unparalleled insight into
                    what crypto coins are set to increase in value, and
                    conversely, which ones to avoid.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 about-content ps-lg-5 m-b30">
                <div className="section-head">
                  <h2 className="title">Our Mission</h2>
                  <p className="m-0 lh-base">
                  Our mission as a cutting-edge crypto investment platform is to empower individuals to leverage the potential of digital assets for financial growth and independence. 
                  Through expert guidance and innovative solutions, we strive to provide a secure and rewarding investment experience that aligns with the dynamic landscape of cryptocurrency markets. 
                  Join us on this journey towards a prosperous crypto future.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
          <img className="bg-shape2" src={Shape3} alt="" />
          <img className="bg-shape3" src={Shape3} alt="" />
          <img className="bg-shape4" src={Shape3} alt="" />
        </section>
        <section className="content-inner p-0 bg-primary-light video-bx-wrapper">
          <img className="bg-shape1" src={Shape1} alt="" />
          <div className="container wow fadeInUp" data-wow-delay="0.4s">
            <div className="section-head  pb-5">
              <div className="row">
                <div className="col-lg-6">
                <h3 style={{color: '#9467fe'}}><i>Stephan Hedges - Founder</i></h3>

                  <img className="founder-img" src={founder} />
                  
                </div>
                
                <div className="col-lg-6">
                  <h2 className="title">Message from founder </h2>
                  <p className=" lh-base">
                    At Benefund we believe in the power of innovation and the boundless opportunities 
                    that the world of blockchain technology has to offer. With dedication, perseverance, 
                    and a commitment to excellence, we are paving the way for a brighter, more decentralized 
                    financial future. Join us on this exciting journey as we shape the future of finance together.
                  </p>
                  <Link
                    to={"/contact-us"}
                    className="btn btn-lg btn-primary btn-shadow text-uppercase"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>

            {/* <div className="video-bx style-1">
              <div className="video-media">
                <img src={videobox} alt="" />
                <Link
                  to={"#"}
                  className="popup-youtube play-icon"
                  onClick={() => setOpen(true)}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.25 3.75L23.75 15L6.25 26.25V3.75Z"
                      stroke="#9467FE"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div> */}
          </div>
        </section>
        <section className="content-inner bg-light pricing-plan-wrapper2">
          <img className="bg-shape2" src={Shape1} alt="" />
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">
                Available Slots <br /> For Investment
              </h2>
            </div>
            <div className="row justify-content-center">
              <PriceBlog />
            </div>
          </div>
        </section>
        <section className="form-sec bg-light pb-5">
          <img className="bg-shape2" src={Shape1} alt="" />

          <div className="container">
            <div className="bg-primary form-wrapper1 style-1">
              <div className="row align-items-center">
                <div className="col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                  <div className="section-head">
                    <h5 className="sub-title text-white">JOIN US</h5>
                    <h2 className="title text-white">Book Your Slot Now</h2>
                  </div>
                </div>
                <div className="col-xl-9">
                  <form className="dzForm" 
                        ref={form} 
                        onSubmit={(e) => formDetails(e)}>
                    <div className="dzFormMsg"></div>
                    <input
                      type="hidden"
                      className="form-control"
                      name="dzToDo"
                      value="Contact"
                    />
                    <input
                      type="hidden"
                      className="form-control"
                      name="reCaptchaEnable"
                      value="0"
                    />

                    <div className="row">
                      <div
                        className="col-md-4 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <input
                          name="fullName"
                          required=""
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                          value={formData.fullName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div
                        className="col-md-4 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <input
                          name="amount"
                          required=""
                          type="text"
                          className="form-control"
                          placeholder="Investment Amount in £"
                          value={formData.amount}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div
                        className="col-md-4 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <input
                          name="email"
                          required=""
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div
                        className="col-md-4 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <input
                          name="phone"
                          required=""
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          value={formData.phone}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div
                        className="col-md-4 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-delay="0.5s"
                      >
                        <input
                          name="walletAddress"
                          required=""
                          type="text"
                          className="form-control"
                          placeholder="Eth Wallet Address"
                          value={formData.walletAddress}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div
                        className="col-md-4 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="btn btn-dark btn-block h-100"
                        >
                          {!emailLoading ? "Submit" : <LoadingSpinner />}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="content-inner bg-white blog-wrapper">
          <img className="bg-shape1" src={Shape1} alt="" />
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-12">
                <div className="section-head ">
                  <h6 className="sub-title text-primary">FROM OUR BLOG</h6>
                  <h2 className="title">Recent News &amp; Updates</h2>
                </div>
                <RecentNews />
              </div>
              <div className="col-xl-5 col-lg-12 m-b30 ">
                <div
                  className="dz-card style-2"
                  style={{ backgroundImage: "url(" + bloglg + ")" }}
                >
                  <div className="dz-category">
                    <ul className="dz-badge-list">
                      <li>
                        <Link to={"#"} className="dz-badge">
                          14 Fan 2022
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="dz-info">
                    <h2 className="dz-title">
                      <Link to={"/blog-details"} className="text-white">
                        Directly support individuals Crypto
                      </Link>
                    </h2>
                    <div className="dz-meta">
                      <ul>
                        <li className="post-author">
                          <Link to={"#"}>
                            <img src={avatar3} alt="" className="me-2" />
                            <span>By Noare</span>
                          </Link>
                        </li>
                        <li className="post-date">
                          <Link to={"#"}> 12 May 2022</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="cfmQFW1DpA0"
        onClose={() => setOpen(false)}
      />
    </>
  );
}
export default AboutUs;
