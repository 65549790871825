import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";

//components
import BannerCard from "./../components/Home/BannerCard";
import OneStop from "./../components/Home/OneStop";
import RecentNews from "./../components/Home/RecentNews";

//images
import baner1 from "./../assets/images/home-banner/img1.png";
import baner2 from "./../assets/images/home-banner/img2.png";
import Shape1 from "./../assets/images/home-banner/shape1.png";
import Shape3 from "./../assets/images/home-banner/shape3.png";
import wallet from "./../assets/images/icons/wallet.svg";
import friend from "./../assets/images/icons/friend.svg";

import coin1 from "./../assets/images/coins/coin1.png";
import coin3 from "./../assets/images/coins/coin3.png";
import coin4 from "./../assets/images/coins/coin4.png";

import bloglg from "./../assets/images/blog/blog-ig.png";
import avatar3 from "./../assets/images/avatar/avatar3.jpg";
import { successfulToast } from "../toasts";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import { backEndUrl, sendFormData } from "../formSubmission";
import emailjs from "@emailjs/browser";

const trustBlog = [
  {
    image: wallet,
    title: "How to Buy Cryptocurrency with cash.",
    shortText:
      "Before placing your investment with us you’ll need to have these two things ready. Firstly you’ll need to purchase some USDT...",
    fullText: `Before placing your investment with us you’ll need to have these two things ready.
	
	Firstly you’ll need to purchase some USDT. This is most commonly done through a crypto exchange such as, Binance or coin base. 
	
	Secondly, you’ll need to download our partner wallet EXODUS to receive your payout from our system.`,
    id: 1,
  },
  {
    image: friend,
    title: "Investment Consultancy and 24 hour help line",
    shortText:
      "We like to get personal with our clients have a quick chat with one of our brokers to find out what suits you best...",
    id: 2,
    fullText: `We like to get personal with our clients have a quick chat with one of our brokers to find out what suits you best. 
	
	All clients will have a chance to have a consultation before placing there investment. Find a slot which suits your needs and financials.
	
	Whilst your trade is live you will have direct contact to your broker until trade has been completed.`,
  },
];

function Home() {
  const [formData, setFormData] = useState({
    walletAddress: "",
    email: "",
  });
  const [box1, setBox1] = useState(false);
  const [box2, setBox2] = useState(false);
  const [selecttext, setSelectText] = useState([coin4, "Bitcoin"]);
  const [selectSlot, setSelectSlot] = useState("Lite £250-£500");
  const [emailLoading, setEmailLoading] = useState(false);
  const form = useRef(null);

  const validateCryptoAddress = (address, coin) => {
    // Regular expressions for Ethereum, Bitcoin, and Tether addresses
    const ethAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/; // ERC20 Ethereum
    const btcAddressRegex = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/; // Bitcoin
    const usdtAddressRegex = /^0x[0-9a-fA-F]{40}$/; // ERC20 Tether
  
    if (
      (coin === "Bitcoin (BTC)" && !btcAddressRegex.test(address)) ||
      (coin === "Ethereum (ERC20)" && !ethAddressRegex.test(address)) ||
      (coin === "Tether (ERC20)" && !usdtAddressRegex.test(address))
    ) {
      alert(`Invalid ${coin} address`);
      return false;
    }
    return true;
  };
  
  

  const formDetails = async (e) => {
    e.preventDefault();
    setEmailLoading(true);


    const { walletAddress, email } = formData;

    const selectedCoin = selecttext[1]; // Get the selected coin from the dropdown

    if (!validateCryptoAddress(walletAddress, selectedCoin)) {
      setEmailLoading(false);
      return; // Prevent form submission
    }
    const templateParams = {
      walletAddress: formData.walletAddress,
      email: formData.email,
      coin: selectedCoin,
      slot: selectSlot, // Include the selected slot from the dropdown
    };
    console.log(templateParams);
    emailjs
      .send(
        "service_xfnsql8",
        "template_mh2p7so",
        templateParams,
        "5AmHByg4twxtIu8ct"
      )
      .then((result) => {
        console.log(result.text);
        successfulToast("Sent! We will reach out to you within 24 hours");
      })
      .catch((error) => {
        console.log(error.text);
        alert(error.text);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleBox1 = () => {
    setBox1(!box1);
  };

  const toggleBox2 = () => {
    setBox2(!box2);
  };

  const renderText = (data) => {
    if (data.id === 1) {
      if (box1 === false) {
        return (
          <>
            <p>{data.shortText}</p>

            <Button
              className="btn btn-primary btn-gradient btn-shadow mt-3"
              onClick={toggleBox1}
            >
              Read More
            </Button>
          </>
        );
      }
      return (
        <>
          <p>{data.fullText}</p>
          <Button
            className="btn btn-primary btn-gradient btn-shadow mt-3"
            onClick={toggleBox1}
          >
            Read Less
          </Button>
        </>
      );
    } else {
      if (box2 === false) {
        return (
          <>
            <p>{data.shortText}</p>

            <Button
              className="btn btn-primary btn-gradient btn-shadow mt-3"
              onClick={toggleBox2}
            >
              Read More
            </Button>
          </>
        );
      }
      return (
        <>
          <p>{data.fullText}</p>
          <Button
            className="btn btn-primary btn-gradient btn-shadow mt-3"
            onClick={toggleBox2}
          >
            Read Less
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="main-bnr style-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 text-center">
                <h1 className="">
                  You invest we do the rest. <br /> Your one stop crypto
                  investment service.
                </h1>

                <p className="text text-primary ">
                  Seamlessly connecting investors with Defi’s most experienced
                  traders.
                </p>
                <Link
                  to={"/about-us"}
                  className="btn space-lg btn-gradient btn-shadow btn-primary "
                >
                  Get Started
                </Link>
                <ul className="image-before">
                  <li className="left-img">
                    <img src={baner1} alt="" />
                  </li>
                  <li className="right-img">
                    <img src={baner2} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
          <img className="bg-shape2" src={Shape1} alt="" />
          <img className="bg-shape3" src={Shape3} alt="" />
          <img className="bg-shape4" src={Shape3} alt="" />
        </div>
        <div className="clearfix bg-primary-light">
          <div className="container">
            <div className="currancy-wrapper home-currancy">
              <div className="row justify-content-center">
                <BannerCard />
              </div>
            </div>
          </div>
        </div>
        <section className="clearfix section-wrapper1 bg-primary-light">
          <div className="container">
            <div className="content-inner-1">
              <div className="section-head text-center">
                <h2 className="title">Why Use Our Service? </h2>
                <p>
                  BeneFund crypto investment service has a proven track record
                  of successful investments, advanced risk management
                  strategies, and commitment to transparency and security. We
                  prioritise client satisfaction and strive to deliver strong
                  returns while minimizing risk.
                </p>
              </div>
              <div className="row">
                {trustBlog.map((data, ind) => (
                  <div className="col-lg-6 m-b30" key={ind}>
                    <div className="icon-bx-wraper style-2">
                      <div className="icon-media">
                        <img src={data.image} alt="" />
                      </div>
                      <div className="icon-content">
                        <h4 className="title">{data.title}</h4>
                        {renderText(data)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="form-wrapper-box style-1 text-center" id="invest">
              <div className="section-head">
                <h4 className="title m-t0">
                  How to place an investment with us?{" "}
                </h4>
                <p>
                  Fill out the form below and we will contact you through email
                  or WhatsApp. Please choose from one of the slots we have
                  available.
                </p>
              </div>
              <form
                className="dz-form"
                ref={form}
                onSubmit={(e) => formDetails(e)}
              >
                <div className="form-wrapper">
                  <div className="flex-1">
                    <div className="row g-3">
                      <div className="col-xl-3 col-md-6 ">
                        <input
                          name="walletAddress"
                          type="text"
                          required
                          placeholder="Wallet Address"
                          className="form-control"
                          value={formData.walletAddress}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-xl-3 col-md-6 ">
                        <Dropdown className="select-drop">
                          <Dropdown.Toggle
                            as="div"
                            className="i-false select-drop-toggle"
                          >
                            <img src={selecttext[0]} alt="" /> {selecttext[1]}{" "}
                            <i className="fa-sharp fa-solid fa-angle-down" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => setSelectText([coin4, "Bitcoin (BTC)"])}
                            >
                              <img src={coin4} alt="" /> Bitcoin (BTC)
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectText([coin3, "Ethereum (ERC20)"])}
                            >
                              <img src={coin3} alt="" /> Ethereum (ERC20)
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectText([coin1, "Tether (ERC20)"])}
                            >
                              <img src={coin1} alt="" /> Tether (ERC20)
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-xl-3 col-md-6 ">
                        <Dropdown className="select-drop">
                          <Dropdown.Toggle
                            as="div"
                            className="i-false select-drop-toggle"
                          >
                            {selectSlot}
                            <i className="fa-sharp fa-solid fa-angle-down" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => setSelectSlot("Lite £250-£500")}
                            >
                              Lite £250-£500
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectSlot("Pro £1000-£2500")}
                            >
                              Pro £1000-£2500
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectSlot("Max £5000-£10,000")}
                            >
                              Max £5000-£10,000
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-xl-3 col-md-6 ">
                        <input
                          name="email"
                          type="text"
                          required
                          placeholder="Email Address"
                          className="form-control"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-gradient btn-primary btn-shadow"
                  >
                    {!emailLoading ? "Get Started" : <LoadingSpinner />}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>
        <section className="content-inner bg-light icon-section section-wrapper2">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">
                One-stop solution to profiting like an experienced{" "}
                <span className="text-primary"> crypto trader. </span>
              </h2>
            </div>
            <div className="row sp60">
              <OneStop />
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>
        {/* <section className="content-inner bg-white blog-wrapper">
          <img className="bg-shape1" src={Shape1} alt="" />

          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-12">
                <div className="section-head ">
                  <h6 className="sub-title text-primary">FROM OUR BLOG</h6>
                  <h2 className="title">Recent News &amp; Updates</h2>
                </div>
                <RecentNews />
              </div>
              <div className="col-xl-5 col-lg-12 m-b30 ">
                <div
                  className="dz-card style-2"
                  style={{ backgroundImage: "url(" + bloglg + ")" }}
                >
                  <div className="dz-category">
                    <ul className="dz-badge-list">
                      <li>
                        <Link to={"#"} className="dz-badge">
                          14 Fan 2022
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="dz-info">
                    <h2 className="dz-title">
                      <Link to={"/blog-details"} className="text-white">
                        Directly support individuals Crypto
                      </Link>
                    </h2>
                    <div className="dz-meta">
                      <ul>
                        <li className="post-author">
                          <Link to={"#"}>
                            <img src={avatar3} alt="" className="me-2" />
                            <span>By Noare</span>
                          </Link>
                        </li>
                        <li className="post-date">
                          <Link to={"#"}> 12 May 2022</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
}
export default Home;
